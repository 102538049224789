import request from "@/utils/request";
import { SendMailType, MailListType, MailDetailParams } from '@/types/mail'
import { PagesType } from '@/types/mail'
type searchEmailUserParams = {
  nickName: string,
  type: number
}
type FavoriteType = {
  favoriteType: number,
  mailIdList: Array<number>
}
type DeleteMailsType = {
  idList: number[],
  type: number
}
type AddCollectType = {
  favoriteType: number,
  mailIdList: number[]
}
type ReadMailType = {
  mailIdList?: number[],
  operationType: number,
  type: number
}
export function mailDetail(params: MailDetailParams) {
  return request.get('/yzapp/sentmail/detail', { params })
}
export function searchEmailUser(params: searchEmailUserParams) {
  return request.get('yzapp/userInfo/selectNameLikeUserList', { params })
}
export function sendMail(data: SendMailType) {
  return request.post('yzapp/draftsmail/saveOrUpdateComplex', data)
}
export function saveDraftMail(data: SendMailType) {
  return request.post('yzapp/draftsmail/saveOrupdateDrafts', data)
}
export function inboxMailList(params: MailListType) {
  return request.get('yzapp/inboxmail/groupList', { params })
}
export function outboxMailList(params: MailListType) {
  return request.get('yzapp/sentmail/list', { params })
}
export function searchMailList(params: MailListType) {
  return request.get('/yzapp/inboxmail/searchHFiveAllList', { params })
}

export function operationUserFavorite(data: FavoriteType) {
  return request.post('yzapp/favoritemail/operationUserFavorite', data)
}
export function cancelUserFavorite(data: FavoriteType) {
  return request.post('yzapp//favoritemail/cancelUserFavorite', data)
}
export function favoriteEmails(params: PagesType) {
  return request.get('yzapp/favoritemail/list', { params })
}
export function recycleBinMails(params: PagesType) {
  return request.get('/yzapp/trashmail/list', { params })
}
export function deleteMails(data: DeleteMailsType) {
  return request.post('/yzapp/draftsmail/deleteMail', data)
}
export function addCollect(data: AddCollectType) {
  return request.post('/yzapp/favoritemail/operationUserFavorite', data)
}
export function cancelCollect(data: { favoriteIdList: number[] }) {
  return request.post('/yzapp/favoritemail/cancelUserFavorite', data)
}
export function readMail(data: ReadMailType) {
  return request.post('/yzapp/mailreadhfive/batchReadMail', data)
}
export function deleteRecycleBinMail(data: { removeMailDtoList: any[] }) {
  return request.post('/yzapp/trashmail/removeMail', data)
}
export function recoveryMail(data: { recoveryMailDtoList: any[] }) {
  return request.post('/yzapp/trashmail/recoveryMail', data)
}
export function withdrawMail(params: { sentId: number }) {
  return request.get('/yzapp/sentmail/withdrawMail', { params })
}
export function updateTimingMail(data: { mailId: number, timingType: number, timingTime?: string }) {
  return request.post('/yzapp/draftsmail/updateMailTimingTime', data)
}
export function replyMailToUserList(params: { mailId: number, type: number }) {
  return request.get('/yzapp/sentmail/replyMailToUserList', { params })
}
export function transactionsMailList(params: { choiceUserId?: number, choiceUserType?: number } & PagesType) {
  return request.get('/yzapp/inboxmail/selectCorrespondenceList', { params })
}
export function selectMailList(params: { type: number } & PagesType) {
  return request.get("/yzapp/inboxmail/list", { params });
}
export function mailDataSync() {
  return request.get("/yzapp/inboxmail/mailDataSync")
}
export function mailSign(data: { foldType: number, mailIdList: number[] }) {
  return request.post("/yzapp/inboxmail/foldMail", data)
}