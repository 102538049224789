
import { configureStore } from '@reduxjs/toolkit'
import menuCurrentReducer from './menuCurrent'
import userInfoReducer from './channelStore'
import isSearchReducer from './searchStore'
import mailStore from './mailStore'
import checkMailStore from './checkMailStore'
import unreadEmails from './unreadEmails'
const store = configureStore({
  reducer: {
    menuCurrent: menuCurrentReducer,
    userInfo: userInfoReducer,
    searchStore: isSearchReducer,
    mailStore: mailStore,
    checkMailStore: checkMailStore,
    unreadEmails: unreadEmails
  }
})

export default store
