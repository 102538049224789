import axios, { AxiosResponse } from "axios";
import { message } from "antd";
let request = axios.create({
  timeout: 10000,
  baseURL: process.env.REACT_APP_BASE_API,
})


// 请求拦截
request.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = localStorage.getItem('token') || ''
    return config;
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截
request.interceptors.response.use(
  (res: AxiosResponse) => {
    const { code, msg } = res.data
    if (code === 500) {
      message.destroy();
      message.error(msg)
    } else if (code === 504) {
      message.destroy();
      message.error('服务器异常')
    } else if (code === 401) {
      message.info('登录已过期，请重新登录')
      localStorage.removeItem('token')
      window.location.href = '/login?path=' + window.location.pathname
    }
    return res.data;
  },
  (error) => {
    message.destroy();
    message.error('服务器异常')
    return { code: 500, msg: '服务器异常' }
    // return Promise.reject(error)
  }
)

export default request