import { createSlice } from "@reduxjs/toolkit";

const menuCurrentStore = createSlice({
  name: "menuCurrent",
  initialState: {
    menuCurrent: localStorage.getItem('menuCurrent')
  },
  reducers: {
    setMenuCurrent: (state, action) => {
      state.menuCurrent = action.payload;
      localStorage.setItem('menuCurrent', action.payload);
    }
  }
})

const { setMenuCurrent } = menuCurrentStore.actions;

const reducer = menuCurrentStore.reducer

export { setMenuCurrent }

export default reducer