import { createSlice } from "@reduxjs/toolkit";

const searchStore = createSlice({
  name: "isSearch",
  initialState: {
    isSearch: false,
    searchType: 0,
    searchValue: ''
  },
  reducers: {
    setIsSearch: (state, action) => {
      state.isSearch = action.payload;
    },
    setSearchType: (state, action) => {
      state.searchType = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    }
  }
})

const { setIsSearch, setSearchType, setSearchValue } = searchStore.actions;

export { setIsSearch, setSearchType, setSearchValue }

const reducer = searchStore.reducer

export default reducer