import { BrowserRouter, useRoutes } from 'react-router-dom';
import route from './router/index';
import IRouteType from './router/model';
import { Route, Routes } from 'react-router-dom';
import React, { Suspense } from 'react';
import { ConfigProvider } from 'antd';
import Loading from './components/Loading';
import zhCN from 'antd/locale/zh_CN';
function App() {
  const routeElement = (route: IRouteType.IRoute): React.ReactNode => {
    if (!route.element) return null;
    return (
      <Suspense fallback={<Loading />}>
        <route.element />
      </Suspense>
    )
  }
  const RouteItem = (route: IRouteType.IRoute) => {

    return <Route key={route.path} path={route.path} element={routeElement(route)}>
      {RouteList(route.children ?? [])}
    </Route>
  }
  const RouteList = (list: IRouteType.IRoute[]) => {
    return list.map((item) => RouteItem(item))
  }

  return (
    <BrowserRouter>
      <ConfigProvider
        autoInsertSpaceInButton={false}
        locale={zhCN}
        theme={{
          token: {
            colorPrimary: '#841EEC'
          },
          components: {
            Button: {
              // primaryColor: '#841EEC',
              // defaultHoverBg: '#f00'
              primaryShadow: '0 2px 0 rgba(255,255,255, 0.1)'
            },
            Table: {
              rowSelectedBg: '#F7F5FA',
              rowSelectedHoverBg: '#fafafa',
              headerBg: '#F9F7FA',
            }
          }
        }}
      >
        <Routes>
          {RouteList(route)}
        </Routes>

      </ConfigProvider>
    </BrowserRouter>
  )
}

export default App;
