import { createSlice } from "@reduxjs/toolkit";
import { selectMailList } from "../api/mail"
const unreadEmails = createSlice({
  name: "unreadEmails",
  initialState: {
    unreadEmailsList: [],
    unreadEmailsCount: 0
  },
  reducers: {
    setUnreadEmailsList(state, action) {
      state.unreadEmailsList = action.payload;
    },
    setUnreadEmailsCount(state, action) {
      state.unreadEmailsCount = action.payload;
    }
  }
})

const { setUnreadEmailsCount, setUnreadEmailsList } = unreadEmails.actions;

const handleGetUnreadEmailsList = () => {
  return async (dispatch: any) => {
    const res = await selectMailList({ type: 1, pageNum: 1, pageSize: 10 })
    if (res.code === 200) {
      dispatch(setUnreadEmailsList(res.data.rows))
      dispatch(setUnreadEmailsCount(res.data.total))
    }
  }
}
export { setUnreadEmailsCount, setUnreadEmailsList, handleGetUnreadEmailsList };

const reducer = unreadEmails.reducer;
export default reducer;