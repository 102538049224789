import { createSlice } from "@reduxjs/toolkit";
import { mailDetail as getMailMsg, recycleBinMails, favoriteEmails, mailDataSync, inboxMailList, outboxMailList, searchMailList, transactionsMailList } from "@/api/mail";
import { MailListType, MailDetailParams } from '@/types/mail'
const mailStore = createSlice({
  name: "mailStore",
  initialState: {
    mailList: [],
    mailDetail: {},
    total: 0,
    emailType: 0, // 1 收件箱 2 发件箱 3 草稿箱 4 待发件 5 已收藏 6 回收站 7 搜索 8 往来邮件
    mailListProp: { pageNum: 1, pageSize: 7, emailType: 0 },
  },
  reducers: {
    setMailList(state, action) {
      state.mailList = action.payload
    },
    setMailDetail(state, action) {
      state.mailDetail = action.payload
    },
    setTotal(state, action) {
      state.total = action.payload
    },
    setEmailType(state, action) {
      state.emailType = action.payload
    },
    setMailProp(state, action) {
      state.mailListProp = action.payload
    }
  }
})

const { setEmailType, setMailProp, setMailDetail, setMailList, setTotal } = mailStore.actions

const handleGetMailList = (data: MailListType) => {
  return async (dispatch: any) => {
    if (Object.keys(data).length === 0 || data.emailType === 0) return
    let res;
    if (data.emailType === 1) {
      res = await inboxMailList(data)
      const response = await mailDataSync()
      if (response.code === 200 && response.data === 2 && data.emailType === 1) {
        res = await inboxMailList(data)
      }
    } else if (data.emailType === 5) {
      res = await favoriteEmails(data)
    } else if (data.emailType === 6) {
      res = await recycleBinMails(data)
    } else if (data.emailType === 7) {
      res = await searchMailList(data)
    } else if (data.emailType === 8) {
      res = await transactionsMailList(data)
    } else {
      res = await outboxMailList(data)
    }
    if (res && res.code === 200) {
      dispatch(setMailList(res.data.rows))
      dispatch(setTotal(res.data.total))
    }
  }
}

const handleGetMailDetail = (data: MailDetailParams) => {
  return async (dispatch: any) => {
    const res = await getMailMsg(data)
    if (res.code === 200) {
      dispatch(setMailDetail(res.data))
    }
  }
}

export { setEmailType, handleGetMailList, handleGetMailDetail, setMailProp, setMailDetail }
const reducer = mailStore.reducer
export default reducer