import { selectUserInfo, selectSign } from "@/api/user";
import { createSlice } from "@reduxjs/toolkit";

const userInfoStore = createSlice({
  name: "userInfo",
  initialState: {
    userInfo: {},
    signContent: {}
  },
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setSignContent: (state, action) => {
      state.signContent = action.payload;
    }
  }
})

const { setUserInfo, setSignContent } = userInfoStore.actions;

const handleGetUserInfo = () => {
  return async (dispatch: any) => {
    const res = await selectUserInfo()
    if (res.code === 200) {
      dispatch(setUserInfo({ ...res.data.sysUser, userLoginStatus: res.data.userLoginStatus }))
      if (res.data.userLoginStatus === 2) {
        window.location.href = '/auditing'
      } else if (res.data.userLoginStatus === 1 && window.location.pathname !== '/login') {
        window.location.href = '/login?pageType=register'
      }
    }
  }
}

const handleSignContent = () => {
  return async (dispatch: any) => {
    const res = await selectSign()
    if (res.code === 200) {
      dispatch(setSignContent(res.data))
    }
  }
}

const reducer = userInfoStore.reducer

export { handleGetUserInfo, handleSignContent }

export default reducer

