import { createSlice } from "@reduxjs/toolkit";

const checkMailStore = createSlice({
  name: "checkMailStore",
  initialState: {
    isCheck: false,
    checkMails: [],
  },
  reducers: {
    setIsCheck: (state, action) => {
      state.isCheck = action.payload;
    },
    setCheckMails: (state, action) => {
      state.checkMails = action.payload;
    }
  }
})

const { setCheckMails, setIsCheck } = checkMailStore.actions

export { setCheckMails, setIsCheck }

const reducer = checkMailStore.reducer

export default reducer;