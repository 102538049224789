import request from "@/utils/request";
import { UserInfoType, CerificateType } from "@/types/user";
interface EditSign {
  signatureContent: string
}
interface AddUserType {
  externalUserName: string,
  externalUserMail: string
}
type EditPhone = {
  phone: string,
  captch: string,
  type: number
}
export function selectUserInfo() {
  return request.post('/yzapp/userInfo/getTokenHFiveUserInfo')
}

export function editSign(data: EditSign) {
  return request.post('/yzapp/signaturehfive/updateCustomInfo', data)
}

export function selectSign() {
  return request.get('/yzapp/signaturehfive/detailCustomInfo')
}
export function addPersonnel(data: AddUserType) {
  return request.post('/yzapp/external/savePersonnel', data)
}
export function selectSysOrExternalUserDetail(params: { inboxType?: number, userId?: number }) {
  return request.get('/yzapp/userInfo/selectSysOrExternalUserDetail', { params })
}
export function sendCode(data: { phone: string, type: number }) {
  return request.post('/yzapp/userInfo/sendNoteCaptcha', data)
}
export function verifyPhoneNumber(data: EditPhone) {
  return request.post('/yzapp/userInfo/verifyCaptcha', data)
}
export function updatePhoneNumber(data: EditPhone) {
  return request.post('/yzapp/userInfo/updateUserInfoPhone', data)
}
export function addCerificate(data: CerificateType) {
  return request.post('/yzapp/certificatehfive/saveCertificateInfo', data)
}
export function editCerificate(data: CerificateType) {
  return request.post('/yzapp/certificatehfive/updateCertificateInfo', data)
}
export function cerificateList() {
  return request.get('/yzapp/certificatehfive/selectCertificateInfoList')
}
export function deleteCerificate(data: { idList: number[] }) {
  return request.post('/yzapp/certificatehfive/deleteCertificate', data)
}
export function deptUserList() {
  return request.get('yzapp/userInfo/deptTreeAndUserList')
}
export function getAllUser() {
  return request.get('yzapp/userInfo/userListAll?type=1')
}
export function getSign() {
  return request.get('yzapp/signaturehfive/list')
}
export function setSign(params: { hfiveSignatureType: number }) {
  return request.get('yzapp/signaturehfive/updateDefaultSignature', { params })
}
export function deleteContact(data: { idList: number[] }) {
  return request.post('/yzapp/external/deletePersonnel', data)
}